@import './assets/styles/main';

html,
body {
   font-family: 'Inter', sans-serif;
}

.btn-mux {
   padding: 12px 16px;
   border: 1px solid #0d9488;
   border-radius: 5px;
   font-size: 24px;
   color: #fff;
   background: #002D8D;
   cursor: pointer;
 }
 /* Customize progress bar color */
 mux-uploader {
   --progress-bar-fill-color: #7e22ce;
   min-height: 600px;
 }
 /* Customize drop area background color & active background color */
 mux-uploader-drop {
   padding: 40px;
   background: #fef9c3;
 }
 
 mux-uploader-drop[active] {
   background: #ffe4e6;
 }


// Material Styles

.mat-form-field-type-mat-date-range-input .mat-form-field-infix {
   display: contents;
   position: absolute;
}

.mat-form-field-wrapper {
   width: 257px;
}

.mat-datepicker-actions {
   gap: 8px;
}

.mat-datepicker-actions > .btn {
   padding: 14px 16px;
}

.mat-form-field {
   position: absolute !important;
   right: 0 !important;
}

// Retirada css deeppurple

.mat-snack-bar-container {
   color: inherit !important;
   background: inherit !important;
   box-shadow: inherit !important;
}

// NgPrime Styles

.p-datatable .p-datatable-thead > tr > th {
   font-family: $font-family-bluewhale !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
   border-color: $primary-color !important;
   background: $primary-color !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
   box-shadow: none !important;
}

.p-datatable .p-datatable-header {
   display: flex;
   justify-content: flex-end;
}

// Material Styles

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
   box-shadow: none !important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
   background-color: transparent !important;
}


// Material Checkbox

.mdc-form-field {
   display: flex;
   flex-direction: row-reverse;
}
.mat-mdc-checkbox label {
   font-family: $font-family-bluewhale;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
   border-color: $primary-color !important;
   background-color: $primary-color !important;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
   color: #fff !important;
}
.mat-mdc-checkbox .mdc-checkbox__ripple {
   background: transparent !important;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
   border-color: $gray-medium;
}


// CkEditor 5

.ck-editor__editable_inline {
   min-height: 100px;
}
