*,
*::after,
*::before {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
}

a {
   text-decoration: none;
   color: inherit;
   cursor: pointer;
}

html {
   --font: 'Inter', sans-serif;
   --font-size: 0.9rem;
   --color-button-text: #fff;
   --color: #115bfb;
   --color-light: #adf;
   --color-dark: #018;
   --radius: 8px;
   --radius-input: 4px;
   --line-hight: 20px;
   --line-hight-cto: 41px;
   scroll-behavior: smooth;
}

body,
input,
textarea,
button {
   font-family: var(--font);
}

img {
   display: block;
}

h1,
h2,
h3 {
   font-family: var(--font);
   color: #222;
}

p {
   font-size: 14px;
}

ul {
   margin: 0;
   list-style-type: none;
}

li {
   font-family: var(--font);
   line-height: var(--line-hight);
}

label {
   display: block;
   margin-bottom: 0.25rem;
}

textarea,
select {
   display: block;
   border: 1px solid #ccc;
   width: 100%;
   padding: 0.8rem;
   border-radius: var(--radius-input);
   background: transparent;
   transition: 0.2s;
   position: relative;
}

input {
   display: block;
   border: none;
   width: 100%;
   padding: 0.8rem;
   background: transparent;
   transition: 0.2s;
   position: relative;
}

select {
   border-color: #9c9c9c;
}

textarea:focus,
select:focus {
   outline: none;
   border-color: var(--color);
   background: #eee;
   box-shadow: 0 0 0 1px var(--color);
}
.invalid {
   border-color: #f00 !important;
}

button {
   cursor: pointer;
   line-height: var(--line-hight);
   border: none;
   border-radius: var(--radius);
   transition: 0.1s;
   background: var(--color);
   color: var(--color-button-text);
   font-weight: 600;
}

.label-button {
   cursor: pointer;
   line-height: var(--line-hight);
   border: none;
   border-radius: var(--radius);
   transition: 0.1s;
   background: var(--color);
   color: var(--color-button-text);
   font-weight: 600;
}

button:disabled {
   opacity: 0.5;
   cursor: default;
}
.btn-loader {
   position: relative;
   user-select: none;
   border-radius: 0.25rem;
   @include transition(0.3s);
   &:after {
      content: '';
      width: 1.2em;
      height: 1.2em;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border: 0.2em solid transparent;
      border-right-color: #fff;
      border-radius: 50%;
      opacity: 0;
   }
   &--start {
      color: transparent;
      pointer-events: none;
      &:after {
         opacity: 1;
         animation: button-anim 0.7s linear infinite;
      }
   }
}
@keyframes button-anim {
   from {
      transform: rotate(0);
   }
   to {
      transform: rotate(360deg);
   }
}

.grid-wrapper {
   background: $background-color;
   padding: 27px 32px 50px 35px;
   display: grid;
   // grid-template-areas: 'sidemenu main';
   // grid-template-columns: 0 1fr;
   // grid-template-rows: 1fr;
   min-height: 100vh;
   box-sizing: border-box;
   @include respond-to('large') {
      grid-template-columns: auto;
   }
   &.full {
      padding: 0;
      margin: 0;
   }
}
// .side {
//    grid-area: sidemenu;
// }
// .content {
//    grid-area: main;
// }

.container {
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
   margin-right: auto;
   margin-left: auto;
   @include respond-to('device') {
       max-width: 540px;
   }
   @include respond-to('small') {
       max-width: 720px;
   }
   @include respond-to('medium') {
       max-width: 960px;
   }
   @include respond-to('large') {
       max-width: 1140px;
   }
   @include respond-to('extra-large') {
       max-width: 1300px;
   }
}

// Presset Class
.btn-ghost {
   padding: 9px 24px;
   background: transparent;
   border-radius: 0.25rem;
   color: $primary-color;
   border: 1px solid $primary-color;
   transition: 0.2s ease-in-out;
   &:hover {
      opacity: 0.8;
   }
}

.btn-primary {
   padding: 12px 24px;
   border-radius: 0.25rem;
}

//SCROLL STYLE
::-webkit-scrollbar {
   width: 2px;
   @include respond-to('medium') {
      width: 5px;
   }
}
::-webkit-scrollbar-track {
   box-shadow: inset 0 0 5px grey;
   border-radius: 10px;
}
::-webkit-scrollbar-thumb {
   background: lighten(gray, 20%);
   border-radius: 10px;
   &:hover {
      background: lighten(gray, 10%);
   }
}

.page-title {
   line-height: 1.36;
   color: $dark;
   margin-bottom: 17px;
   padding: 0 16px;
   font: {
      family: $font-family-bluewhale;
      weight: 400;
      size: 1.75rem;
   }
   @include respond-to('medium') {
      margin-bottom: 32px;
      padding: 0;
   }
}

.options-button {
   color: $primary-color;
   background: transparent;
   position: absolute;
   bottom: 24px;
   right: 0;
   background-size: 15px 15px;
   width: 150px;
   &:after{
      content: "\E815";
      font-family: "bluewhale";
      font-size: 1rem;
      color: $primary-color;

   }
   font: {
      size: 1rem;
      family: $font-family-bluewhale;
      weight: normal;
   }
   &:hover,
   &:focus {
      box-shadow: none;
   }
   @include respond-to('medium') {
      position: relative;
      top: 0;
   }

   &:disabled{
      color: $secondary-color;
      cursor: default;
      user-select: none;
   }
}

.loading{
   display:flex;
   justify-content: center;
}

.msg-error{
   color: #f00;
   font-size: 12px;
}


@media print
{
   .hide-print{
      display: none !important;
   }
}
