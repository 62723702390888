@import '@angular/cdk/overlay-prebuilt.css';

.cdk-global-scrollblock {
   overflow-y: hidden;
   top: 0 !important;
}
.cdk-global-overlay-wrapper {
   overflow: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   animation: swaller 0.3s;
}

@keyframes swaller {
   0% {
      transform: scale(0.7);
   }
   45% {
      transform: scale(1.05);
   }
   80% {
      transform: scale(0.95);
   }
   100% {
      transform: scale(1);
   }
}

.custom-modal {
   min-height: 307px;
   padding: 16px 24px 32px;
   border-radius: 4px;
   background: #fff;
   overflow: auto;
   display: block;
   position: relative !important;
   cursor: default;
   bottom: 77px;
   @include respond-to('medium') {
      border-radius: 0.25rem !important;
   }
}

.associate-modal {
   min-height: 100px;
   padding: 16px 24px 32px;
   background: #fff;
   overflow: auto;
   display: block;
   position: relative !important;
   cursor: default;
   bottom: 77px;
   @include respond-to('medium') {
      border-radius: 0.25rem !important;
   }
}

.background-modal {
   background: rgba(0, 0, 0, 0.8);
   cursor: default;
}

.confirmation {
   width: 399px;
   min-height: 270px;
   bottom: 36px;
}

.disable {
   min-height: 270px;
   bottom: 36px;
}

.cropper {
   width: 50vw;
   min-height: 60vh;
   border-radius: 10px;
   bottom: 0;
   padding: 0;
   @include respond-to('extra-large') {
      bottom: 40px;
      width: 40vw;
   }
}
